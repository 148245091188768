import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const TipsForCampaignBanner = ({ location }) => {
  const title =
    "【初心者必見！】SNSキャンペーンバナー制作4つのポイントをデザイナーが実例付きで大公開！"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]
  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="【初心者必見！実例つき 】SNSキャンペーンバナー制作4つのポイント"
          pagedesc="初めてキャンペーンバナーを作ることになった！デザイナーじゃないのにバナーを制作を任された！どうしよう！そんなバナー制作初心者向けに現役デザイナーがTwitterバナー作成時のポイントをまとめました。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/tips-for-campaign-banner/tips-for-campaign-banner.jpg"
          pagepath="/blog/tips-for-campaign-banner"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は6分で読むことができます
            </div>
            <h1>
              【初心者必見！】SNSキャンペーンバナー制作4つのポイントをデザイナーが実例付きで大公開！
            </h1>
            <div className="blog__sns-container">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
            {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">2022年11月17日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="壁に写真を貼っている男性"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/tips-for-campaign-banner/tips-for-campaign-banner.jpg"
                className="w-full"
              />
              <h2>SNSキャンペーンの成功は＜8割＞バナーの品質にかかっている</h2>
              <p>
                Instagram、Twitter、LINEなど、SNSを活用したキャンペーンが活発に開催されています。そのキャンペーンには、必ずと言っていいほどバナー画像が添付されています。バナーがないとユーザの目に止まらないからです。
              </p>
              <p>
                ユーザは日々さまざまなバナーを目にしていて、その中から参加するキャンペーンを選んでいます。ユーザにキャンペーンに参加してもらうためには、効果的なバナーを作成する必要があるのです。バナーの良し悪しがキャンペーンの結果に影響することもあります。
              </p>
              <p>
                この記事では私がベテランデザイナーの方から頂いたフィードバックを元に、バナー制作のポイントを初心者の方向けにまとめました。ポイントを押さえてキャンペーンを盛り上げるバナーを作りましょう！
              </p>
            </figure>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    成果の出るバナー制作のポイント1.キャンペーン内容を整理して始める
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    成果の出るバナー制作のポイント2.とにかくそろえる！
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    成果の出るバナー制作のポイント3.文字のあしらい（装飾）を加える
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id4" className="toc__link">
                    成果の出るバナー制作のポイント4.使用する素材は、使用OKかつ元データを使う
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id5" className="toc__link">
                    クオリティの高いバナーでSNSキャンペーンをさらに盛り上げよう
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id1">
                成果の出るバナー制作のポイント1.キャンペーン内容を整理して始める
              </h2>
              <p>
                まずはバナーに入れる情報を整理します。面倒かもしれませんが、できれば書き出しましょう。いきなりパソコンに向かってデータを作り始めるのはおすすめしません。作業に取り掛かる前の下準備がとても大切です。工程を省くと、結果的にかえって時間がかかります。
              </p>
              <p>キャンペーンのバナーに必要な情報は</p>
              <ul>
                <li>キャンペーン名</li>
                <li>景品</li>
                <li>当選人数</li>
                <li>キャンペーンの開催期間</li>
                <li>応募方法</li>
                <li>注釈</li>
              </ul>
              <p>
                このあたりが主かと思います。キャンペーンの内容によって異なるので、担当者にしっかりと確認しましょう。データ制作を始めてから情報量の増減があると、修正に意外と時間がかかります。
              </p>
              <p>この段階ではまだ色はつけないでおきましょう。</p>
              <p>
                <br />
                内容を整理したら、次は情報の優先順位をつけましょう。優先順位の高い情報は、この後のデータ作成の際に目立たせます。Twitterキャンペーンバナーでは、景品や当選人数を1番目立たせているパターンが多いです。景品が豪華な場合は景品を、当選人数が多い場合には当選人数を優先しましょう。注釈は小さくてもいいですね。
              </p>
              <ol>
                <li>景品or当選人数</li>
                <li>キャンペーン名</li>
                <li>応募方法</li>
                <li>キャンペーンの開催期間</li>
                <li>注釈</li>
              </ol>
              <p>
                上記のようなパターンが多いです。キャンペーンの目的によっては、キャンペーン名を1番目立たせることもあります。
              </p>
              <img
                className=""
                placeholder="tracedSVG"
                alt="景品を目立たせた例とキャンペーン名を目立たせた例"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/tips-for-campaign-banner/tips-for-campaign-banner-2.jpg"
              />
              <p>
                どれも重要な情報ですが、あれもこれも目立たせようとするとメリハリがなくなり、野暮ったくなってしまいます。色々なバナーを見て研究してみましょう。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/twitter-campaign-banner"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンバナー【実例20選】デザイナーが解説！
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id2">
                成果の出るバナー制作のポイント2.とにかくそろえる！
              </h2>
              <p>
                2つ目のポイントは、とにかく揃えることです。揃えるものはマージン、フォント、トンマナの3つです。
              </p>
              <p>最初はマージンについて。</p>
              <p>
                バナーに入れる情報が整理できたら、パソコンでデータを作り始めます。まずは文字を配置しましょう。配置の段階ではまだ色はつけません。先ほど決めた優先順位に沿ってサイズを決めていきましょう。
              </p>
              <p>
                文字を配置する際はマージンを揃えるようにします。全部かっちり揃えてしまうと固くなりすぎてしまうので、上下左右のどこかの端の位置を揃えましょう。
              </p>
              <p>
                <br />
                次はフォントについて。
              </p>
              <p>
                フォントの種類は多くて3種類までにしましょう。色々な種類のフォントがあって目移りしてしまうかもしれませんが、フォントを複数使うとまとまりがなくなってしまいます。
              </p>
              <p>
                キャンペーンバナーはゴシック体が使われていることが多いです。景品や企業のイメージ次第では明朝体を使うのも効果的ですが、特に理由がなければゴシック体を使うのが無難です。
              </p>
              <p>
                数字やアルファベットは欧文フォントを使うとカッコよく仕上がります。「円」や「名様」などの単位に関する文字や、「＆」「＋」などの記号、約物はサイズを小さくするとメリハリがあり見やすくなります。
              </p>
              <p>
                <br />
                最後はトンマナです。
              </p>
              <p>
                トンマナとはトーン＆マナーのことで、簡単に説明するとデザインに一貫性を持たせることです。
              </p>
              <p>
                キャンペーンの内容や企業のイメージに合わせて全体を整えましょう。
              </p>
              <p>
                使用する色は3色程度に絞ります。ベースカラー、メインカラー、アクセントカラーの割合を70:25:5を目安にすると良いです。
              </p>
              <img
                className=""
                placeholder="tracedSVG"
                alt="トンマナが揃っていない例とトンマナが揃っている例"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/tips-for-campaign-banner/tips-for-campaign-banner-3.jpg"
              />
              <p>
                左側のバナーはトンマナが揃っていない例です。トンマナが合っていないため、チグハグな印象になってしまっていますね。右側はトンマナを修正して揃えた例です。統一感が出てまとまった雰囲気になりました。
              </p>
              <p>
                「どんな色を使ったらいいか分からない」というときは「配色
                イメージ ○○」などのワードで調べてみると良いでしょう。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/banner-request"
                    target="_blank"
                    rel="noreferrer"
                  >
                    バナー依頼時にデザイナーがマーケターに気をつけて欲しい５つのポイント
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id3">
                成果の出るバナー制作のポイント3.文字のあしらい（装飾）を加える
              </h2>
              <p>
                3つ目のポイントは文字のあしらいについて。あしらいとは装飾やディティールのことです。
              </p>
              <p>
                文字のサイズや位置を調整するだけでは、まだ賑やかさに欠け寂しい印象なのではないでしょうか？
              </p>
              <img
                className=""
                placeholder="tracedSVG"
                alt="マージン等は揃っているが寂しい印象の例とあしらいを足してあざやかな印象の例"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/tips-for-campaign-banner/tips-for-campaign-banner-4.jpg"
              />
              <p>
                左側のバナーは、マージンやトンマナは揃っていますが、カッチリしていて賑やかさに欠けています。右側のバナーは文字に縁取りや影をつけ、リボンの中に文字を入れ、写真素材を付け足しました。左側に比べ賑やかさが出ています。
              </p>
              <p>
                少しあしらいを工夫するだけでもグッとクオリティが上がるので、ぜひ色々な種類を試してみてください。高価なデザインソフトがなくても、簡単に実践できるものがたくさんあります。
              </p>
              <p>
                ただし、多用しすぎるとゴチャゴチャしてメリハリが無くなってしまうので、特に目立たせたい重要な部分などにポイントを絞って使用しましょう。
              </p>
              <br />
              <h3>あしらいの種類</h3>
              <ul>
                <li>丸や四角など図形の中に文字を入れ込む</li>
                <li>帯を敷く</li>
                <li>下線を引く</li>
                <li>強調線をつける</li>
                <li>集中線</li>
                <li>フチをつける</li>
                <li>影（ドロップシャドウ）をつける</li>
                <li>グラデーション</li>
                <li>パターン（柄）を入れる</li>
                <li>版ズレ</li>
                <li>ワープ</li>
                <li>立体表現</li>
                <li>イラストや枠の中に入れ込む</li>
                <li>リボンの中に入れ込む</li>
              </ul>
              <br />
              <h3>あしらいを取り入れた一例</h3>
              <img
                className=""
                placeholder="tracedSVG"
                alt="あしらいを取り入れた一例"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/tips-for-campaign-banner/tips-for-campaign-banner-5.jpg"
              />
              <p>
                上記のあしらいは、どれも皆さん一度は目にしたことがあるのではないでしょうか？
              </p>
              <p>
                モチーフのイラストは自分で描けなくても大丈夫です。フリー素材サイトにも素敵なものがたくさんあります。リボンや枠も多く用意されているので、どんどん利用させてもらいましょう。
              </p>
              <p>
                こちらに記載した以外にも様々な手法があります。まずは簡単なものから取り入れてみましょう。難しいことをしなくてもガラッと印象を変えられますよ。
              </p>
            </div>
            <div>
              <h2 id="id4">
                成果の出るバナー制作のポイント4.使用する素材は、使用OKかつ元データを使う
              </h2>
              <p>
                キャンペーンバナーでは景品の写真や、キャラクターのイラスト、企業のロゴが使用されているのをよく見かけます。バナー作成を依頼された場合は、事前に指定の写真や画像があるかどうか担当者に確認しましょう。指定の素材がある場合はデータをもらいます。
              </p>
              <p>
                指定の画像がない場合は文字のみで仕上げたり、素材サイトからイラストや写真をダウンロードして使用したりします。
              </p>
              <p>
                キャンペーンバナーでは紙吹雪やガーランド、クラッカーなど楽しい雰囲気を盛り上げるようなイラストがよく使用されます。
              </p>
              <img
                className=""
                placeholder="tracedSVG"
                alt="PARKLoTのキャンペーンバナーサンプル"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/tips-for-campaign-banner/tips-for-campaign-banner-6.jpeg"
              />
              <p>
                こちらのバナーのようにガーランド＋紙吹雪など、複数の要素を組み合わせて使われることも多いです。
              </p>
              <br />
              <p>素材を使用する際の注意点としては</p>
              <ul>
                <li>
                  写真のメイン部分に文字や装飾など、他の要素が被らないようにする
                </li>
                <li>
                  企業のロゴを入れる際は必ず元データをもらう
                  <ul>
                    <li>
                      「サイトから拾ってきて」と言われることもあるかもしれませんが、これでは解像度が足りずに画像が荒くなる可能性があります。
                    </li>
                    <li>
                      絶対に比率を変えないよう注意しましょう。白抜きなど色を変更したい場合は、必ず担当者に確認して許可をもらってからにします。
                    </li>
                  </ul>
                </li>
                <li>
                  ネット検索で出てきた画像をそのまま保存して使用するのはNG！
                  <ul>
                    <li>
                      違法行為にあたったり、炎上する可能性もあります。素材を使用する場合は、必ず正規の手段で入手しましょう。フリー素材でも商用利用は不可の場合もあるので要確認です。
                    </li>
                  </ul>
                </li>
              </ul>
              <br />
              <p>
                思わぬトラブルを避けるためにも、上記の点には注意しましょう。
              </p>
              <p>
                写真やイラストはユーザの目を引き、賑やかさも出しやすいため重宝します。規約をしっかりと守って利用しましょう。
              </p>
            </div>
            <div>
              <h2 id="id5">
                クオリティの高いバナーでSNSキャンペーンをさらに盛り上げよう
              </h2>
              <p>
                「キャンペーンバナー制作時の4つのポイント」いかがだったでしょうか？普段何気なく見ているバナーですが、いざ作るとなると案外考えることが多いですよね。限られたスペースに必要な情報をまとめ、ユーザにキャンペーン参加を促せるようなバナーを作りましょう。
              </p>
              <p>
                自分で作るのが難しい場合は、プロに依頼するというのもひとつの手です。最初にも記した通り、バナーの良し悪しがキャンペーンの結果を大きく左右することもあります。デザインについての知識やスキルがある人に依頼すれば、クオリティの高い訴求力のあるバナーを使用できます。
              </p>
              <p>
                PARKLoTではTwitterキャンペーンを簡単に開催できるツールのご提供のほか、オリジナルバナーの作成も承っています。ぜひキャンペーンの開催と併せてご検討ください。
              </p>
            </div>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="momoko" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/how-to-get-followers-enterprise"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【今すぐ実践！】Twitter
                    企業アカウントでフォロワーを増やす方法
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/banner-request"
                    target="_blank"
                    rel="noreferrer"
                  >
                    バナー依頼時にデザイナーがマーケターに気をつけて欲しい５つのポイント
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/comparison-no-tool"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンで本当にツールは必要なのか？
                    お金をかけずに手動でできる方法は？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/customer-appeal-SNS"
                    target="_blank"
                    rel="noreferrer"
                  >
                    訴求効果ピカイチ!!
                    手軽で効果的「SNS広告」2大スタイル徹底解説～フォロー＆リツイートキャンペーンとハッシュタグキャンペーンどちらをやるべき？
                    ～
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-parklot-is-inexpensive"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}

export default TipsForCampaignBanner
